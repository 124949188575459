<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="w-full form-row">
      <FinalForm
        ref="form"
        @submit="saveData"
        :initialValues="initialValues"
        class="pr-2 w-full"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >renters insurance
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >RENTER'S OR LIABILITY INSURANCE</label
          >
          <div class="form-row">
            <div class="text-title">Liability Limit $</div>
            <TextInputSmall name="liabilityLimit" type="number" />
            <div class="text-title">Per Occurance</div>
          </div>
          <div class="form-row">
            <div class="text-title">Default Fee $</div>
            <TextInputSmall name="defaultFee" type="number" />
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import TextInputSmall from "@/components/form/TextInputSmall";
import { FinalForm } from "vue-final-form";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import ModalFooter from "@/components/ui/modals/ModalFooter";

export default {
  mixins: [ModalNavigation, InitializeFormMixin],
  components: {
    Loader,
    FinalForm,
    TextInputSmall,
    ModalFooter,
  },
  data() {
    return {
      liabilityLimit: "",
      defaultFee: "",
      footer: {
        primaryButton: "save",
      },
      loading: false,
    };
  },
  computed: {
    initialValues() {
      return {
        liabilityLimit: "",
        defaultFee: "",
      };
    },
  },
  methods: {
    saveData() {},
  },
};
</script>

<style scoped>
.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
</style>